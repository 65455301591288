.register-cont {
    min-height: 650px;
    position: relative;
    margin-top: 95px;
}

.register-cont>div {
    min-height: inherit;
}

.register-details {
    background: linear-gradient(250deg, rgba(153, 153, 255, 1) 33%, rgba(102, 102, 255, 1) 85%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.register-form {
    background-color: #ffffff;
    display: flex;
}

.heading-cont-reg {
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    color: #004280;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding: 10px 25px;
    height: 65px;
}
.register-details .heading-cont-reg{
    color: #ffffff;
}
.register-details .cont-details{
    background:none;
}
.register-details .col-details-cont{
    padding-right:20px;
    background-color: #ffffff;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
    border: 0px;
}
.register-details .stepNumber{
    padding: 10px 20px;
    font-size: 25px;
    background-color: #004280;
    color: #ffffff;
    font-weight: 100;
    margin-right: 10px;
    min-width: 120px;
}
.step-def {    
    width: 100%;
    text-align: center;
    padding: 10px 35px;
}

.step-def .steps {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.steps .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    color: #999;
    font-size: 22px;
    font-weight: 500;
    border-radius: 50%;
    background: #fff;
    border: 4px solid #b3b3b3;
    transition: all 200ms ease;
    transition-delay: 0s;
    z-index: 1;    
}

.steps .circle.active {
    border-color: #00cc99;
    transition: border-color 0.35s ease-out;
    color: #00cc99;
} 

.steps .progress-bar {
    position: absolute;
    height: 4px;
    width: 100%;
    background: #b3b3b3;
}
.progress-bar .indicator {
    position: absolute;
    height: 100%;
    width: 0%;
    background: #00cc99;
    transition: all 4s ease;
}
.regform-cont{
    padding: 10px 25px;
    margin: 15px auto;
    border-top: 2px solid #004280;
    width: 90%;
}
.inputrow>div{
    padding: 2px 15px;
}
.myregisterbtn{
    margin: 15px;
    font-size: 18px !important;
    font-weight: 600 !important;
}
.mynextbtn{
    margin: 15px;
    font-size: 18px !important;
    font-weight: 600 !important;
}
.myskipbtn{
    margin: 15px;
    font-size: 18px !important;
    font-weight: 600 !important;
    position: relative;
    float: right;
}
.myprofilebtn{
    font-size: 18px !important;
    font-weight: 600 !important;
    width: 100%;
}

/*For Contact*/
.contact-container{
    padding: 10px;
    padding-top: 0px;
    margin: 5px 0px;
    min-height: 250px;
    position: relative
}
.contact-container > .no-data{
    font-size: 22px;
    font-weight: 800;
    text-align: center;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    color: #80aaff;
}
.contact-card{
    margin: 6px;
    border: 2px solid #4da6ff;
    border-radius: 10px;
    flex: 0 0 auto;
    width: 30%;
}
.contact-card>div{
    min-width: 100%;
    padding: 2px 7px;
    color: #4d4d4d;
}
.contact-card i{
    margin-right:4px ;
    font-weight: 600;
    font-size: 14.5px;
}
.contact-type{
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 5px;
    border-bottom: 2px solid #4da6ff;
    background-color: #4da6ff;
    border-radius: 5px 5px 0px 0px;
    color: #ffffff !important;
}
.contact-phone>i{
    color: #ffa366;
}
.contact-phone{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 3px;    
}
.contact-email>i{
    color:#b3b3ff ;
}
.contact-email{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.contact-whatsapp>i{
    color: #00cc99;
}
.contact-whatsapp{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 3px;    
}
.heading-contact{
    width: 100%;
    text-align: center;
    font-size: 26px;
    font-family: 'Poppins', sans-serif;
    color: #8080ff;
    font-weight: 800;
    letter-spacing: 0.5px;
    padding: 8px 25px;
    border-top: 2px solid #007acc;
    margin-top: 10px;
    margin-bottom: 10px;
}

.top-header{
	position:fixed;
	width:100%;
	padding:10px 20px;
	z-index:11;
	background-color: #ffffff;
    color: #4f5767;
}
.logo{
	position:relative;
    float:left;
}
.logo img{
	width:150px;
	height:75px;
	padding:3px;
}
/* Top Header NavMenu */
.menu{
	width: 87%;
    float: left;
    position: relative;
    text-align: center;
    margin-top: 35px;
}
.my-nav{
	width:100%;
	margin-left:70px;
}
.my-nav ul{
	list-style-type: none;
  	margin: 0;
  	padding: 0;
	position:relative;
}
.my-nav .glv_1>li{
	float:left;
	padding:5px 2px;
	text-align:center;
	margin:0px 10px;
	display:block;
}
.my-nav .glv_1>li>a{
	font-family: 'Poppins', sans-serif;
	font-size:14px;
	text-transform:uppercase;
	font-weight:700;
	letter-spacing: 1px;
	padding: 0px 5px;
	cursor:pointer;
	color: inherit !important;
}
.glv_1>li:after {
  display:block;
  content: '';
  border-bottom: solid 3px #007fc4;  
  transform: scaleX(0);  
  transition: transform 300ms ease-in-out;
}
.glv_1>li>a>i{ 
	transition: transform 0.3s;
	font-size:12px;
}
.glv_1>li:hover:after { transform: scaleX(1); }
.glv_1>li:hover i{ transform:rotate(180deg);}
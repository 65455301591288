.login-cont{
	min-height: 650px;
    position: relative;
    margin-top: 95px;
}
.login-cont>div {
	min-height: inherit;
}
.login-details{
	background: linear-gradient(280deg, rgba(21,61,104,1) 33%, rgba(100,138,178,1) 95%);
	display:flex;
	-webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;	
}
.login-form{
	background-color:#cccccc;
	display:flex;
	-webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}
.cont-details{
	display:inline-block;
	border-radius:5px;
	background-color:rgb(255, 255, 255);
	padding:20px;
	font-size: 16px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
	color: #4f5767;
}
.heading-cont{
	width:350px;
	height:50px;
	text-align:center;
	font-size:30px;
	font-family: 'Poppins', sans-serif;
	color:#004280;
	font-weight:800;
	text-transform:uppercase;
	letter-spacing:0.5px;
}
.heading-cont img{
    width:350px;
    margin-left: 20px;
}
.body-cont{
	width: inherit;
	position:relative;
	margin-top:10px;
}
.myloginbtn {
  flex: 1 1 auto;
  padding: 5px 15px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: #ffffff;
  border-radius: 8px;
  font-size:20px;
  font-weight:bold;
  font-family: din, sans-serif;
  border:none;
  outline:none;
  width:100%;
  margin-top:30px;
  margin-bottom:30px;
}
.myloginbtn{
	background-image: linear-gradient(to right, #84fab0 0%, #8fd3f4 51%, #84fab0 100%);
}
.myloginbtn:hover {
  background-position: right center;
  color:#ffffff;
}
.myloginbtn i{
	font-size:18px;
	margin-left:10px;
}
.myloginbtn:hover i{
    margin-left:60%;
    transition: margin 600ms linear;
}
.text-cont{
	width:100%;
	text-align:center;
	margin-bottom:10px;
	font-size:17px;
}
.text-cont>a{
	color:#66c2ff;
	margin:0px 2px;
}
.col-details-cont{
	width:100%;
	margin:10px 0px;
	font-size:13px;
	background-color:#f2f2f2;
	border:1px solid #cccccc;
	border-radius:5px;
	overflow:hidden;
    padding-right: 8px;
}
.col-details-cont img{
	margin-right:8px;
	background-color:#ffffff;
	padding:4px;
	width:58px;
	height:58px;	
}
.login_error{
	text-align: center;
	font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color: #ff4d4d;
    font-weight: 800;
	margin-bottom: -10px;
	margin-top: 5px;
}
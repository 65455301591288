.footer{
	position:relative;
	background-color:#313c4e;
}
.social-media{
	padding: 20px 30px;
}
.copyright{
	text-align:center;
	position:relative;
	text-transform:uppercase;
	background-color: #252E3E;
    padding: 15px 30px;
    color: #e9ebec;
	font-family: 'Poppins', sans-serif;
	font-size:16px;
	font-weight:600;
	letter-spacing:1px;
}
.copyright p{
	opacity:0.4;
	margin-bottom: 0px;
}
.social-media i{
	font-size:25px;
	display:inline-block;
	margin:0px 12px;
	color:#939aa7;
}
.social-media a:hover i{
	color:#abb0ba;
}
.designer{
	text-align: right;
	font-family: 'Poppins', sans-serif;
	color: #e9ebec;
	opacity:0.4;
}
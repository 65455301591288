.fullformdetails {
    padding: 15px 120px;
    background-color: #ffffff;
    min-height: calc(100vh - 90px);
    max-height: calc(100vh - 90px);
}

.fulldetailstable {
    padding: 15px 50px;
    background-color: #ffffff;
}

.table-heading {
    width: 100%;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    padding: 15px;
    text-align: center;
    font-weight: 600;
    color: #4da6ff;
}

.no-data {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #ff6666 !important;
    border-bottom: 2.5px solid #f2f2f2;
}

.loading-gif {
    width: 100%;
    text-align: center;
    font-size: 38px;
    font-family: 'Poppins', sans-serif;
    margin: auto;
    color: #00b386;
    font-weight: 600;
}

.my-table i {
    padding: 4px 6px;
    cursor: pointer;
    font-size: 17px;
}

.my-table .fa-ban {
    color: rgba(255, 128, 128, 1);
}

.my-table .fa-edit {
    color: rgba(51, 187, 255, 1);
}

.my-table .fa-trash {
    color: rgba(255, 128, 128, 1);
}
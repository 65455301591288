/* Left & Right Panel */
.totalbody {
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    max-width: 100%;
}

.totalbody>div {
    flex-shrink: 0;
}

.left-sideSection {
    min-height: 100%;
    width: 15%;
    flex: 0 0 auto;
}

.right-sideSection {
    min-height: 100%;
    flex: 0 0 auto;
    width: 85%;
    padding-right: 7px;
}

.mainbody-container {
    width: 100%;
    margin-top: 60px;
    min-height: calc(100vh - 60px);
}

/* Top Header MenuBar */
.topNavBar {
    position: fixed;
    min-height: 50px;
    padding: 5px 10px;
    width: 85%;
    background-color: #ffffff;
    z-index: 20;
    top: 0px;
    font-family: 'Poppins', sans-serif;
    -webkit-box-shadow: 0px 1px 20px 1.5px rgba(0, 0, 0, 0.31);
    -moz-box-shadow: 0px 1px 20px 1.5px rgba(0, 0, 0, 0.31);
    box-shadow: 0px 1px 20px 1.5px rgba(0, 0, 0, 0.31);
}

.greetType {
    position: relative;
    float: left;
    font-size: 13px;
    font-weight: 900;
    letter-spacing: 1px;
    padding: 5px;
    color: #8c8c8c;
}

.greetType>span {
    color: #00ace6;
    font-weight: 800;
}

.rightPart {
    position: relative;
    float: right;
    font-weight: 900;
    letter-spacing: 1px;
    color: #8c8c8c;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

.optionItem {
    flex: 0 0 auto;
    font-size: 23px;
    padding: 0px 10px;
    cursor: pointer;
    margin: auto;
}

.chat-icon>.mark-num {
    background-color: #ff6666;
    margin-top: 2px;
    margin-left: -2px;
}

.notification-icon>.mark-num {
    background-color: #ffbb33;
    margin-top: 2px;
    margin-left: -10px;
}

.cusName {
    font-size: 14px;
    margin: auto;
}

.mark-num {
    min-height: 11px;
    min-width: 11px;
    position: absolute;
    padding: 2px;
    border-radius: 50%;
    margin-right: 5px;
}

.profileimg {
    width: 50px;
    height: 50px;
    padding: 2px;
    background-color: #d9d9d9;
    border-radius: 50%;
}

/* Side Menubar */
.fullmenu {
    width: 100%;
    min-height: 100%;
    background-color: rgba(50, 59, 78, 1);
    z-index: 30;
    position: relative;
}

.logo-menu {
    padding: 15px 30px;
    background-color: #e6e6e6;
    text-align: center;
    margin-bottom: 5px;
}

.logo-menu>img {
    width: 100px;
    height: auto;
}

.sidemenu-itemlist {
    margin-top: 25px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.menu-item {
    padding: 8px 10px;
    letter-spacing: 1px;
    margin: 2px 0px;
    color: #f2f2f2;
    background-color: rgba(65, 78, 102, 1);
    font-size: 13px;
    padding-left: 20px;
    cursor: pointer;
}

.submenu-item {
    padding: 6px 12px;
    letter-spacing: 1px;
    margin: 4px 0px;
    color: #f2f2f2;
    padding-left: 35px;
    cursor: pointer;
    font-size: 12px;
}

.menu-item i {
    margin: 0px 5px;
    font-size: 15px;
    margin-left: 0px;
}

.submenu-item i {
    margin: 0px 5px;
    font-size: 10px;
    margin-left: 0px;
}

.menu-Submenu {
    margin: 5px 0px;
    background-color: rgba(65, 78, 102, 1);
}

.menu-Submenu.selected {
    border-left: 3px solid #f2f2f2;
}

.submenu-item.selected {
    color: rgba(254, 119, 134, 1);
}

.menu-item.selected {
    background-color: rgba(254, 103, 120, 1);
    font-weight: bold;
    border-left: 3px solid #f2f2f2;
}
.menu-Submenu.selected .menu-item.selected{
    border-left: none;
}
.menu-Submenu .menu-item{
    border-bottom: 1px solid rgba(50, 59, 78, 1);
}
/* Navigation Bar */
.navigation-container{
    width: 100%;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    background-color: rgba(65, 78, 102, 1);
    color: #ffffff;
    font-weight: 800;
}
.nav-item{
    background-color: rgba(254, 119, 134, 1);
    padding: 5px 20px;
    display: inline-block;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-left: -10px;
}
.nav-item:first-child{
    margin-left: 0px;
    z-index: 10;
    position: relative;
}
.nav-item:last-child{
    background-color: rgba(0, 179, 134,1);
    z-index: 5;
    position: relative;
}
.button-container{
    text-align: right;
}
.my-button{
    width: auto;
    height: auto;
    display: inline-block;
    text-align: center;
    padding: 5px 10px;
    color: #fff;
    border-radius: 3px;
    margin-right: 2px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0.6px;
    border: 0px;
    font-family: 'Poppins', sans-serif;
}
/*Panel with scroll*/
.big-panel {
    overflow-y: scroll !important;
}
.big-panel::-webkit-scrollbar {
	width:6px;
}
.big-panel::-webkit-scrollbar-track { 
	background-color:#ffffff;
}
.big-panel::-webkit-scrollbar-thumb {
	background:#3e6595; 
}
.big-panel::-webkit-scrollbar-thumb:hover {
	background:#3e6595; 
}

/* Color */

.bg_lightgray{
	background-color:#f2f2f2;
}
.bg_gray{
	background-color:rgba(242, 242, 242, 0.6);
}
.bg-lightgreen{
	background-color:rgba(0, 153, 115, 0.9);
}
.bg-black {
   background-color: #4f585d;
}
.bg-spblue{
	background-color: #2196f3 !important;
}
.bg-green{
	background-color: #66bb6a !important;
}
.bg-lightblue{
	background-color: #64b5f6 !important;
}
.bg-lightred{
	background-color: #e57373 !important;
}
.bg-deeporange{
	background-color: #ff8a65 !important;
}
.bg-skyblue{
	background-color: #4dd0e1 !important;
}
.bg-yellow{
	background-color: #ffaa00 !important;
}